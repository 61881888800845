import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const StampedPlugin = loadable(() => import('./StampedPlugin'));

Builder.registerComponent(StampedPlugin, {
  name: 'Stamped Plugin',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'publicAPIKey',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'storeUrl',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productId',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productName',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productUrl',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productImage',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productDescription',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'productSku',
      type: 'string',
      defaultValue: ''
    }
  ]
});
