import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  min-width: 700px;
  &.body {
    border: 1px solid #ccd2e3;
    border-radius: 8px;
    margin-top: 10px;
  }
  & .min-width-190 {
    min-width: 190px;
  }
`;
export const Thead = styled.thead`
  & th {
    width: 25%;
    text-align: center;
  }
`;

export const Tbody = styled.thead`
  & tr {
    width: 100%;
    &.top td {
      border-top: 0;
    }
  }
  & td {
    width: 25%;
    text-align: center;
    padding: 10px;
    &.label {
      text-align: left;
      background-color: #f8f8f8;
    }
    border-top: 1px solid #ccd2e3;
    &.radius-top-left {
      border-top-left-radius: 8px;
    }
    &.radius-bottom-left {
      border-bottom-left-radius: 8px;
    }
    &.text-bold {
      font-weight: bold;
    }
  }
  width: 100%;
`;
