import { Builder } from '@builder.io/react';
import DeliveryFrequency from './DeliveryFrequency';

Builder.registerComponent(DeliveryFrequency, {
  name: 'Delivery Frequency',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'subscriptionSchedules',
      type: 'list',
      defaultValue: [
        { id: '1:month', label: 'Every 4 weeks (Most Common)' },
        { id: '2:month', label: '2 Months' },
        { id: '3:month', label: '3 Months' }
      ],
      subFields: [
        {
          name: 'id',
          type: 'string',
          defaultValue: '1:month',
          required: true
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Month (Most Common)',
          required: true
        },
        {
          name: 'defaultSelection',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'extraObjects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              defaultValue: '',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              defaultValue: '',
              required: true
            }
          ]
        }
      ]
    },
    {
      name: 'useSelectComponent',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ]
});
