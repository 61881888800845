import React from 'react';
import loadable from '@loadable/component';

const PricePerChewV1 = loadable(() => import('./PricePerChewV1'));
const PricePerChewV2 = loadable(() => import('./PricePerChewV2'));

const PricePerChew = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <PricePerChewV2 {...props} />;
    default:
    case 'version1':
      return <PricePerChewV1 {...props} />;
  }
};

export default PricePerChew;
