import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 6px #0000001a;

  & .fade-enter {
    opacity: 0;
  }
  & .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in, margin 300ms ease-out;
  }
  & .fade-exit {
    opacity: 1;
  }
  & .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out, margin 300ms ease-in;
  }
`;

export const TubItem = styled.div`
  font-family: 'Neuzeit Grotesk';
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid #ccd2e3;
  cursor: pointer;
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f0f0f08c;
  }
  ${({ active, activeBg }) => {
    if (active) {
      return `
          background-color: ${activeBg};
          &:hover {
            background-color: ${activeBg};
            }
          `;
    }
  }}
`;

export const TubItemTitle = styled.div``;

export const TubItemDiscount = styled.div`
  background: #33498e;
  border-radius: 4px;
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  padding: 4px;
  width: 95px;
  text-align: center;
  ${({ bg, bgSub, isSub }) => `background: ${isSub ? bgSub : bg};`}
`;

export const TubItemPrice = styled.div`
  min-width: 60px;
  text-align: right;
  margin-left: 10px;
`;

export const TubItemColumn = styled.div`
  display: flex;
  flex-direction: row;

  & .fade-enter {
    opacity: 0;
  }
  & .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  & .fade-exit {
    opacity: 0.5;
  }
  & .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`;

export const PetMonth = styled.span`
  color: #6677aa;
`;

export const Dot = styled.div`
  border: 1px solid #ccd2e3;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  background-color: #fff;
  position: relative;
  margin-right: 10px;

  ${({ active }) => {
    if (active) {
      return `
        border: 1px solid #001C72;
        
        &:after {
            width: 9px;
            height: 9px;
            display:block;
            background-color:#001C72;
            content: " ";
            border-radius: 50%;
            top: 3px;
            left: 3px;
            position:absolute;
        }
        `;
    }
  }}
`;
