import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const LottieAnimation = loadable(() => import('./LottieAnimation'));

Builder.registerComponent(LottieAnimation, {
  name: 'Lottie Animation',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'animationFile',
      type: 'file',
      allowedFileTypes: ['json', 'svg'],
      required: true
    }
  ]
});
