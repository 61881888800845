import React from 'react';

const FunnelContext = React.createContext({});

const FunnelProvider = ({ value, children }) => {
  return (
    <FunnelContext.Provider value={value}>{children}</FunnelContext.Provider>
  );
};

export default FunnelContext;

export { FunnelProvider };
