import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const BackButtonContainer = loadable(() => import('./BackButtonContainer'));

Builder.registerComponent(withChildren(BackButtonContainer), {
  name: 'Slides:BackButtonContainer',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'checkQueryParams',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'queryParamString',
      type: 'string',
      defaultValue: 'layer',

      showIf: o => o.get('checkQueryParams')
    },
    {
      name: 'defaultLayer',
      type: 'string',
      defaultValue: 'layer-0',
      showIf: o => o.get('checkQueryParams')
    },
    {
      name: 'triggerScrollToTop',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ]
});
