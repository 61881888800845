import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './Title.styles';

const Title = props => {
  const { title, hashtag } = props;
  const { pageDesign } = useContext(FunnelContext);
  return (
    <S.Container pageDesign={pageDesign} id={hashtag}>
      <S.Title dangerouslySetInnerHTML={{ __html: title }} />
    </S.Container>
  );
};

export default Title;
