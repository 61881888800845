import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const CanvasScratch = loadable(() => import('./CanvasScratch'));

Builder.registerComponent(CanvasScratch, {
  name: 'Slides:CanvasScratch',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'width',
      type: 'number',
      defaultValue: 640
    },
    {
      name: 'height',
      type: 'number',
      defaultValue: 480
    },
    {
      name: 'frontImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg'],
      required: true,
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
    },
    {
      name: 'backImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
    },

    // advanced options
    {
      name: 'frontBgColor',
      type: 'string',
      defaultValue: '#CCCCCC',
      advanced: true
    },
    {
      name: 'scratchSize',
      type: 'number',
      defaultValue: 50,
      advanced: true
    },
    // {
    //   name: 'autoScale',
    //   type: 'boolean',
    //   defaultValue: true,
    //   advanced: true,
    // },
    {
      name: 'redirect',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'redirectUrl',
      type: 'url',
      defaultValue: 'https://offer.petlabco.co.uk/joint-care-chews',
      advanced: true,
      showIf: options => options.get('redirect') === true
    },
    {
      name: 'redirectOn',
      type: 'number',
      defaultValue: 50,
      advanced: true,
      showIf: options => options.get('redirect') === true
    },
    {
      name: 'nextLayer',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: options => options.get('redirect') === false
    },
    {
      name: 'nextLayerId',
      type: 'string',
      defaultValue: 'layer-1',
      advanced: true,
      showIf: options => {
        return (
          options.get('nextLayer') === true && options.get('redirect') === false
        );
      }
    },
    {
      name: 'nextLayerOn',
      type: 'number',
      defaultValue: 50,
      advanced: true,
      showIf: options => {
        return (
          options.get('nextLayer') === true && options.get('redirect') === false
        );
      }
    }
  ]
});
