import React, { useContext, useEffect, useState } from 'react';
import * as S from './TubSelectorV3.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import { CSSTransition } from 'react-transition-group';

const TubSelector = props => {
  const { tubs, collapsible, isExpanded } = props;

  const [expanded, setExpanded] = useState(isExpanded ? true : false);

  const {
    currency,
    currentCategory,
    setCurrentProduct,
    currentTubIndex,
    setCurrentTubIndex,
    setBumpOfferIndex,
    setCurrentTub,
    extraObjects: extraObjectsContext,
    setExtraObjects,
    tubIndex,
    setTubIndex
  } = useContext(FunnelContext);

  const currencySymbol = currency?.symbol || `$`;

  useEffect(() => {
    const { extraObjects } = tubs[currentTubIndex] || {};

    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (!key) return false;
        const newExtraObjects = Object.assign(extraObjectsContext, {
          [key]: value
        });
        return setExtraObjects(newExtraObjects);
      });
    }
  }, [tubs, currentTubIndex, setExtraObjects, extraObjectsContext]);

  const handleClick = (
    index,
    tub,
    currentProduct,
    extraObjects,
    bumpOfferIndex
  ) => {
    if (collapsible && currentTubIndex === index) {
      setTubIndex(index);
      setExpanded(tubIndex < 0 ? false : !expanded);
      return;
    }

    setExpanded(false);
    setTubIndex(index);
    setCurrentTubIndex(index);
    setCurrentTub(tub);
    setCurrentProduct(currentProduct);
    setBumpOfferIndex(bumpOfferIndex || 0);

    // set extraObjects
    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (!key) return false;
        const newExtraObjects = Object.assign(extraObjectsContext, {
          [key]: value
        });
        return setExtraObjects(newExtraObjects);
      });
    }
  };

  const duration = 300,
    onEnter = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    },
    onEntering = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExit = node => {
      node.style.marginTop = '';
      node.style.marginBottom = '';
    },
    onExiting = node => {
      node.style.marginTop = `-${node.offsetHeight}px`;
      node.style.marginBottom = `0px`;
    };

  return (
    <S.Container>
      {tubs.map((tub, index) => {
        const {
          title,
          extraObjects,
          discountTagBg,
          discountTagBgSub,
          backgroundColor,
          bumpOfferIndex
        } = tub;
        const isActive = currentTubIndex === index;
        const currentProduct =
          currentCategory === `onetime`
            ? `onetime_${index}`
            : `subscription_${index}`;

        const savings = ProductPrice({
          type: 'savings',
          currentCategory,
          currentProduct
        });

        return (
          <CSSTransition
            key={title}
            in={
              (!isActive && collapsible && expanded) || isActive || tubIndex < 0
            }
            timeout={duration}
            classNames="slide"
            unmountOnExit
            onEnter={onEnter}
            onEntering={onEntering}
            onExit={onExit}
            onExiting={onExiting}
          >
            <S.TubItem
              key={title}
              active={isActive}
              activeBg={backgroundColor}
              onClick={() =>
                handleClick(
                  index,
                  tub,
                  currentProduct,
                  extraObjects,
                  bumpOfferIndex
                )
              }
            >
              <S.TubItemColumn>
                <S.Dot active={isActive} />
                <S.TubItemTitle>{title}</S.TubItemTitle>
              </S.TubItemColumn>

              <S.TubItemColumn>
                <CSSTransition
                  key={title}
                  in={parseInt(savings) > 0}
                  timeout={duration}
                  classNames="fade"
                  unmountOnExit
                >
                  <S.TubItemDiscount
                    bg={discountTagBg}
                    bgSub={discountTagBgSub}
                    isSub={currentCategory === 'subscriptions'}
                  >
                    Save {currencySymbol}
                    {savings}
                  </S.TubItemDiscount>
                </CSSTransition>

                <S.TubItemPrice>
                  {currencySymbol}
                  <ProductPrice
                    type="discounted_price"
                    currentCategory={currentCategory}
                    currentProduct={currentProduct}
                  />
                  {currentCategory === 'subscriptions' && (
                    <S.PetMonth>/mo</S.PetMonth>
                  )}
                </S.TubItemPrice>
              </S.TubItemColumn>
            </S.TubItem>
          </CSSTransition>
        );
      })}
    </S.Container>
  );
};

export default TubSelector;
