import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const UserAddress = loadable(() => import('./UserAddress'));

Builder.registerComponent(UserAddress, {
  name: 'User Address',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'updateToApi',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'endpointApiUrl',
      type: 'string',
      defaultValue:
        'https://staging-subscription.thepetlabco.com/api/revival/billing-details',
      helperText: '{--URL--}/api/revival/billing-details',
      showIf: o => o.get('updateToApi')
    }
  ]
});
