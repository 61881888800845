import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const SavingsPopup = loadable(() => import('./SavingsPopup'));
const SavingsPopupContent = loadable(() => import('./SavingsPopupContent'));

Builder.registerComponent(SavingsPopup, {
  name: 'Savings Popup Text',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'display',
      type: 'string',
      enum: ['link', 'next-tub'],
      defaultValue: 'link'
    },
    {
      name: 'linkText',
      type: 'string',
      defaultValue: 'See All Savings'
    },
    {
      name: 'nextTubText',
      type: 'string',
      defaultValue: 'Add another tub for just'
    }
  ]
});

Builder.registerComponent(SavingsPopupContent, {
  name: 'Savings Popup Content',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    }
  ]
});
