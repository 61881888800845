import React from 'react';
import ProductTitleV1 from './ProductTitleV1';
import ProductTitleV2 from './ProductTitleV2';

const ProductTitle = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <ProductTitleV2 {...props} />;
    default:
    case 'version1':
      return <ProductTitleV1 {...props} />;
  }
};

export default ProductTitle;
