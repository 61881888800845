import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const SubPopupText = loadable(() => import('./SubPopupText'));

Builder.registerComponent(SubPopupText, {
  name: 'Sub Popup Text',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'string',
      defaultValue:
        'Flexible subscription management - change, pause, skip or cancel your subscription online',
      helperText: 'add this `|` to split text'
    },
    {
      name: 'faqURL',
      type: 'string',
      defaultValue: ''
    }
  ]
});
