import React from 'react';
import loadable from '@loadable/component';

const ToggleContainerSingle = loadable(() => import('./ToggleContainerSingle'));
const ToggleContainerMultiple = loadable(() =>
  import('./ToggleContainerMultiple')
);

const ToggleContainer = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'multiple':
      return <ToggleContainerMultiple {...props} />;
    default:
    case 'single':
      return <ToggleContainerSingle {...props} />;
  }
};

export default ToggleContainer;
