import React from 'react';
import loadable from '@loadable/component';

const ExtraObjectsContainerSingle = loadable(() =>
  import('./ExtraObjectsContainerSingle')
);

const ExtraObjectsContainer = props => {
  const { type } = props;
  switch (type) {
    default:
    case 'single':
      return <ExtraObjectsContainerSingle {...props} />;
  }
};

export default ExtraObjectsContainer;
