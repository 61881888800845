import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const DelayText = loadable(() => import('./DelayText'));

Builder.registerComponent(DelayText, {
  name: 'Delay Text',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '[delivery-date]',
      helperText: `Template Tags: [delivery-date]
      `
    }
  ]
});
