import { Builder } from '@builder.io/react';
import Title from './Title';

Builder.registerComponent(Title, {
  name: 'Title',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'This is a Title'
    },
    {
      name: 'hashtag',
      type: 'string',
      defaultValue: 'hastag',
      required: false
    }
  ]
});
