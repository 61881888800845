import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const UserText = loadable(() => import('./UserText'));

Builder.registerComponent(UserText, {
  name: 'User Text',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '[name][declined-reason]',
      helperText:
        'template tags: [name][declined-reason][total][currency-symbol]'
    }
  ]
});
