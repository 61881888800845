import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const ProductImages = loadable(() => import('./ProductImages'));
const ProductImagesTrigger = loadable(() => import('./ProductImagesTrigger'));

Builder.registerComponent(withChildren(ProductImages), {
  name: 'Product Images',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'items',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'showIn',
          type: 'list',
          defaultValue: [],
          subFields: [
            {
              name: 'currentCategory',
              type: 'string',
              enum: ['subscriptions', 'onetime'],
              defaultValue: 'subscriptions'
            },
            {
              name: 'currentTubIndex',
              type: 'number',
              defaultValue: 0
            }
          ]
        }
      ]
    }
  ]
});

Builder.registerComponent(withChildren(ProductImagesTrigger), {
  name: 'Product Images Trigger',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'showChildIndex',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'activeClassName',
      type: 'string',
      enum: ['design1'],
      defaultValue: 'design1'
    }
  ]
});
