import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const DelaySubscription = loadable(() => import('./DelaySubscription'));

Builder.registerComponent(withChildren(DelaySubscription), {
  name: 'Delay Subscription',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'expiredTokenRedirect',
      type: 'string',
      defaultValue: 'https://thepetlabco.com/pages/token-expired'
    },
    {
      name: 'redirectIfAuthFails',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'addLoadingCover',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'subIdsKey',
      type: 'string',
      defaultValue: 'sub_ids'
    }
  ]
});
