import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  text-align: left;
  @media ${queries.md} {
    text-align: center;
  }
`;

export const Name = styled.div`
  color: #453009;
  font-weight: 700;
  margin: 0;
  font-size: 34px;
`;

export const Tubs = styled.div`
  margin: 10px 0 5px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: #909090;
  & span {
    color: #4f822e;
    font-weight: 700;
  }
`;

export const Reviews = styled.div`
  font-size: 20px;
  color: #909090;
  display: flex;
  flex-direction: row;
`;

export const Star = styled.i`
  margin-right: 2px;
  content: ' ';
  display: block;
  background-image: url('/images/fa-star.svg');
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

export const Stars = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReviewText = styled.div`
  display: inline;
  margin-left: 10px;
`;

export const ProductPrices = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 8px 0;
  & > div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    & > span {
      text-decoration: line-through;
      color: #979797;
      margin: 0;
      font-size: 17px;
    }
    & > h2 {
      font-size: 32px;
      font-weight: 600;
      color: #c00;
      text-align: left;
      margin: 4px 0 0 0;
      line-height: 1;
    }
  }
  & > p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 70%;
    min-height: 62px;
    background-color: #fffbdc;
    border: 1px solid #ede7b4;
    border-radius: 2px;
    position: relative;
    color: #616161;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    padding: 11px;
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      bottom: 18px;
      left: -13px;
      border-right: 12px solid #ede7b4;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      bottom: 18px;
      left: -12px;
      border-right: 12px solid #fffbdc;
    }
  }

  @media ${queries.md} {
    display: none;
  }
`;
