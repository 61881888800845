import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const LocalCartItems = loadable(() => import('./LocalCartItems'));

Builder.registerComponent(LocalCartItems, {
  name: 'Local Cart Items',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: []
});
