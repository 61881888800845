import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const LayerContainer = loadable(() => import('./LayerContainer'));

Builder.registerComponent(withChildren(LayerContainer), {
  name: 'Slides:LayerContainer',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'layerId',
      type: 'string',
      defaultValue: 'layer-0',
      required: true
    },
    {
      name: 'checkQueryParams',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'queryParamString',
      type: 'string',
      defaultValue: 'layer',
      advanced: true,
      showIf: o => o.get('checkQueryParams')
    },
    {
      name: 'clearAnswers',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      helperText: 'Clears `answers` key in the local storage'
    },
    {
      name: 'clearExtraObjects',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      helperText: 'Clears `extraObjects` key in the local storage'
    },
    {
      name: 'removeLocalStorageItems',
      type: 'list',
      advanced: true,
      subFields: [
        {
          name: 'key',
          type: 'string'
        }
      ]
    }
  ],
  noWrap: true
});
