import React from 'react';
import loadable from '@loadable/component';

const AnswerContainerSingle = loadable(() => import('./AnswerContainerSingle'));

const AnswerContainer = props => {
  const { type } = props;
  switch (type) {
    default:
    case 'single':
      return <AnswerContainerSingle {...props} />;
  }
};

export default AnswerContainer;
