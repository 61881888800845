import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const Loading = loadable(() => import('./Loading'));

Builder.registerComponent(Loading, {
  name: 'Slides:Loading',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'design',
      type: 'string',
      enum: ['paw', 'spinner', 'progressbar', 'dog-catch'],
      defaultValue: 'spinner'
    },
    {
      name: 'redirect',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'delaySeconds',
      type: 'number',
      defaultValue: 30,
      showIf: options => options.get('redirect') === true
    },
    {
      name: 'showCountdown',
      type: 'boolean',
      defaultValue: true,
      showIf: options => options.get('redirect') === true
    },
    {
      name: 'countdownPercentage',
      type: 'boolean',
      defaultValue: false,
      showIf: options => options.get('showCountdown') === true
    },
    {
      name: 'useUrl',
      type: 'boolean',
      defaultValue: false,
      showIf: options => options.get('redirect') === true
    },
    {
      name: 'nextLayerId',
      type: 'string',
      defaultValue: 'layer-0',
      showIf: options =>
        options.get('redirect') === true && options.get('useUrl') === false
    },
    {
      name: 'url',
      type: 'string',
      defaultValue: '',
      showIf: options => options.get('useUrl') === true
    },
    {
      name: 'loadingSize',
      type: 'number',
      defaultValue: 16,
      showIf: options => options.get('design') === 'spinner',
      advanced: true
    },
    {
      name: 'loadingColor',
      type: 'string',
      defaultValue: '#3498db',
      showIf: options => options.get('design') === 'spinner',
      advanced: true
    }
  ],
  noWrap: true
});
