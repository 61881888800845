import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const UserSubscriptions = loadable(() => import('./UserSubscriptions'));

Builder.registerComponent(UserSubscriptions, {
  name: 'User Subscriptions',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    }
  ]
});
