import React, { useContext, useEffect } from 'react';
import FunnelContext from '../../context/FunnelContext';

const FatherLayerContainer = ({ children }) => {
  const { setCurrentLayer, previousLayer } = useContext(FunnelContext);

  useEffect(() => {
    window.addEventListener('popstate', e => {
      setCurrentLayer(previousLayer.pop() || 'layer-0');
    });
    return () => {
      window.removeEventListener('popstate', () => {});
    };
  }, [previousLayer, setCurrentLayer]);

  return <>{children}</>;
};

export default FatherLayerContainer;
