import React from 'react';
import loadable from '@loadable/component';

const FaqsV1 = loadable(() => import('./FaqsV1'));
const FaqsV2 = loadable(() => import('./FaqsV2'));

const Faqs = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <FaqsV2 {...props} />;
    default:
    case 'version1':
      return <FaqsV1 {...props} />;
  }
};

export default Faqs;
