import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const Container1 = loadable(() => import('./Container1'));

Builder.registerComponent(withChildren(Container1), {
  name: 'Sales Letter Container 1',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://icons.iconarchive.com/icons/mcdo-design/letter/48/Letter-pen-icon.png',
  canHaveChildren: true,
  // static: true,
  // noWrap: true,
  // hideFromInsertMenu: true,
  inputs: [
    {
      name: 'width',
      type: 'number',
      defaultValue: 765
    }
  ]
});
