import { Builder } from '@builder.io/react';
import ComparePlansTable from './ComparePlansTable';

Builder.registerComponent(ComparePlansTable, {
  name: 'Compare Plans Table',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'columns',
      type: 'list',
      subFields: [
        {
          name: 'label',
          type: 'string',
          defaultValue: `Recommended for your dog`
        },
        {
          name: 'category',
          type: 'string',
          defaultValue: 'subscriptions',
          enum: ['subscriptions', 'onetime']
        },
        {
          name: 'tubIndex',
          type: 'number',
          defaultValue: 0
        },
        {
          name: 'freeGift',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'freeShipping',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'chewsTubs',
          type: 'string',
          defaultValue: `30 / 1 Tub`
        },
        {
          name: 'frequency',
          type: 'string',
          defaultValue: `Monthly`
        }
      ]
    }
  ]
});
