import React from 'react';
import NavLinksV1 from './NavLinksV1';

const NavLinks = props => {
  const { designVersion } = props;
  switch (designVersion) {
    default:
    case 'version1':
      return <NavLinksV1 {...props} />;
  }
};

export default NavLinks;
