import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const Receipt = loadable(() => import('./Receipt'));

Builder.registerComponent(withChildren(Receipt), {
  name: 'Receipt',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: []
});
