import React, { useCallback, useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './DeliveryFrequency.styles';
import Select from 'react-select';

function DeliveryFrequency(props) {
  const { subscriptionSchedules, useSelectComponent } = props;

  const {
    pageDesign,
    frequency,
    setFrequency,
    extraObjects,
    setExtraObjects
  } = useContext(FunnelContext);

  const scheduleExtraObjects = useCallback(
    value => {
      const [{ extraObjects: extraObjs }] = subscriptionSchedules.filter(
        s => s.id === value
      );
      if (extraObjs) {
        extraObjs.map(({ key, value }) => {
          setExtraObjects(
            Object.assign(extraObjects, {
              [key]: value
            })
          );
          return null;
        });
      }
    },
    [subscriptionSchedules, setExtraObjects, extraObjects]
  );

  if (!subscriptionSchedules) return ``;

  const options = subscriptionSchedules.map(item => {
    return {
      value: item.id,
      label: item.label
    };
  });

  const frequencyOnChange = evt => {
    setFrequency(evt.target.value);
    scheduleExtraObjects(evt.target.value);
  };

  const handleSelectChange = ({ value }) => {
    setFrequency(value);
    scheduleExtraObjects(value);
  };

  const SelectContainer = () => {
    if (useSelectComponent) {
      return (
        <Select
          classNamePrefix="frequency-select"
          options={options}
          onChange={handleSelectChange}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          defaultValue={options.filter(option => option.value === frequency)}
          inputId="frequency-select-input"
        />
      );
    } else {
      return (
        <select
          defaultValue={frequency}
          aria-label="Frequency"
          onBlur={frequencyOnChange}
          onChange={frequencyOnChange}
        >
          {subscriptionSchedules.map(({ id, label }) => {
            return (
              <option key={id} value={id}>
                {label}
              </option>
            );
          })}
        </select>
      );
    }
  };

  return (
    <S.Frequency pageDesign={pageDesign} className="frequency">
      <SelectContainer />
    </S.Frequency>
  );
}

export default DeliveryFrequency;
