import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const DelayButton = loadable(() => import('./DelayButton'));

Builder.registerComponent(withChildren(DelayButton), {
  name: 'Delay Button',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'label',
      type: 'string',
      defaultValue: 'Select date',
      required: true
    },
    {
      name: 'daysAmount',
      type: 'number'
    },
    {
      name: 'delayLengthText',
      type: 'string'
    },
    {
      name: 'subIdsKey',
      type: 'string',
      defaultValue: 'sub_ids'
    }
  ]
});
