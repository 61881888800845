import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const ScrollButtonContainer = loadable(() => import('./ScrollButtonContainer'));

Builder.registerComponent(withChildren(ScrollButtonContainer), {
  name: 'Scroll Button Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'scrollToElement',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'positionX',
      type: 'number',
      defaultValue: 0,
      showIf: options => !options.get('scrollToElement')
    },
    {
      name: 'positionY',
      type: 'number',
      defaultValue: 0,
      showIf: options => !options.get('scrollToElement')
    },
    {
      name: 'elementId',
      type: 'string',
      defaultValue: 'element-id',
      showIf: options => options.get('scrollToElement')
    }
  ]
});
