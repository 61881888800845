import React, { useContext } from 'react';
import * as S from './TubSelectorV1WithChildren.styles';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { ProductPrice } from '../../utils/productPrice';

const TubSelector = props => {
  const { tubs, boxDesign, children } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    currentCategory,
    setCurrentProduct,
    tubIndex,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    pageDesign,
    setCurrentTub
  } = context;

  const currencySymbol = currency?.symbol || '$';

  const TubImage = ({ image, image2, active, alt }) => {
    if (active) {
      switch (pageDesign) {
        case 'design2':
          return <img src={image} alt={alt} />;
        case 'design1':
        default:
          return <img src={image2} alt={alt} />;
      }
    } else {
      return <img src={image} alt={alt} />;
    }
  };

  const Price = ({ showPrice, index }) => {
    if (showPrice) {
      return (
        <S.Price>
          {currencySymbol}
          <ProductPrice
            type="discounted_price"
            currentCategory={currentCategory}
            currentProduct={
              currentCategory === `onetime`
                ? `onetime_${index}`
                : `subscription_${index}`
            }
          />
        </S.Price>
      );
    } else {
      return '';
    }
  };

  const ChilrenContainer = ({ index, children, editing }) => {
    if (children && children[index]) {
      return children[index];
    } else {
      if (editing) {
        return <S.NoChildren>insert component here</S.NoChildren>;
      } else {
        return ``;
      }
    }
  };

  return (
    <S.Container className={`${boxDesign || `wide`}`}>
      <S.TubList>
        {tubs?.map((tub, index) => {
          const {
            title,
            image,
            image2,
            showPrice,
            hideLabel,
            backgroundColor
          } = tub;
          const activeTub = currentTubIndex === index ? 'current' : '';
          const hoverTub = tubIndex === index ? 'active' : '';
          const activeImage = tubIndex === index || currentTubIndex === index;

          const TubLabel = () => {
            if (hideLabel) {
              return ``;
            } else {
              return <span>{title}</span>;
            }
          };

          const BoxDesignInner = () => {
            return (
              <>
                <TubLabel />
                <Price showPrice={showPrice} index={index}>
                  {currencySymbol}XX.xx
                </Price>
                <ChilrenContainer index={index} editing={Builder.isEditing}>
                  {children}
                </ChilrenContainer>
              </>
            );
          };

          const BoxDesign = () => {
            switch (boxDesign) {
              case 'wide':
                return (
                  <div>
                    <BoxDesignInner />
                  </div>
                );
              case 'normal':
              default:
                return <BoxDesignInner />;
            }
          };

          return (
            <S.TubListItem
              key={index}
              className={`${hoverTub} ${activeTub}`}
              onClick={() => {
                setTubIndex(index);
                setCurrentTubIndex(index);
                setCurrentTub(tub);
                setCurrentProduct(
                  currentCategory === `subscriptions`
                    ? `subscription_${index}`
                    : `onetime_${index}`
                );
              }}
              pageDesign={pageDesign}
            >
              <S.TubListItemContainer
                className="box"
                pageDesign={pageDesign}
                backgroundColor={backgroundColor}
              >
                <TubImage
                  image={`${image}?width=100`}
                  image2={`${image2}?width=100`}
                  active={activeImage}
                  alt={title}
                />
                <BoxDesign />
              </S.TubListItemContainer>
            </S.TubListItem>
          );
        })}
      </S.TubList>
    </S.Container>
  );
};

export default TubSelector;
