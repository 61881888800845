import React from 'react';
import loadable from '@loadable/component';

const FreeGiftSelectorV1 = loadable(() => import('./FreeGiftSelectorV1'));

const FreeGiftSelector = props => {
  const { version } = props;

  switch (version) {
    default:
    case 'version1':
      return <FreeGiftSelectorV1 {...props} />;
  }
};

export default FreeGiftSelector;
