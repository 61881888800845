import React, { useContext } from 'react';
import * as S from './TubSelectorV2.styles';
import FunnelContext from '../../context/FunnelContext';

const TubSelectorV2 = props => {
  const { tubs } = props;
  const {
    currentCategory,
    currentTubIndex,
    setCurrentTubIndex,
    setTubIndex,
    setCurrentProduct
  } = useContext(FunnelContext);

  const handleClick = newIndex => {
    setCurrentTubIndex(newIndex);
    setTubIndex(newIndex);
    setCurrentProduct(
      currentCategory === `subscriptions`
        ? `subscription_${newIndex}`
        : `onetime_${newIndex}`
    );
  };

  const handleMinusButtonClick = () => {
    if (currentTubIndex > 0) {
      handleClick(currentTubIndex - 1);
    }
  };

  const handlePlusButtonClick = () => {
    if (currentTubIndex < tubs.length - 1) {
      handleClick(currentTubIndex + 1);
    }
  };

  return (
    <S.Container>
      <S.Button
        onClick={handleMinusButtonClick}
        disabled={currentTubIndex === 0}
      >
        <span>-</span>
      </S.Button>
      {tubs.map(
        (tub, index) =>
          index === currentTubIndex && (
            <S.Body>
              <S.Body1>
                <S.Title>{tub.title}</S.Title>
                <S.SubTitle>{tub.subTitle}</S.SubTitle>
              </S.Body1>
              <S.Body2>{tub.showTag && <S.Tag>{tub.tagText}</S.Tag>}</S.Body2>
            </S.Body>
          )
      )}
      <S.Button
        className="plus"
        disabled={currentTubIndex === tubs.length - 1}
        onClick={handlePlusButtonClick}
      >
        <span>+</span>
      </S.Button>
    </S.Container>
  );
};

export default TubSelectorV2;
