import styled from 'styled-components';

export const TubIndexWrapper = styled.div`
  width: 100%;
  margin: 0;
`;

export const TubIndex = styled.div`
  padding: 10px 15px;
  margin: 0;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: relative;

  &.active {
    border: 1px solid #e26447;
    -webkit-box-shadow: 0 0 5px 0 #e26447;
    box-shadow: 0 0 5px 0 #e26447;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                border: 1px solid #E6E8F0;
                box-shadow: 0px 0px 6px #0000001A;
                padding: 20px 20px;

                &.active {
                    border: 1px solid #AD3B42;
                    -webkit-box-shadow: 0 0 5px 0 #AD3B42;
                    box-shadow: 0 0 5px 0 #AD3B42;
                }
                `;
      default:
        return;
    }
  }}

  &:after {
    content: ' ';
    background-repeat: no-repeat;
    display: block;
    width: 51px;
    height: 56px;
    position: absolute;
    top: -8px;
    right: -10px;

    @media (max-width: 500px) {
      background-size: 90%;
      width: 41px;
      height: 45px;
    }

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
             width:52px;
             height: 66px;
             background-image: url("/images/downsell-ribbon2.svg");
             background-size: cover;
             top: -8px;
             right: -15px;

             @media (max-width: 500px) {
                background-size: 100%;
                width:52px;
                height: 66px;
                right: -5px;
             }

             `;
      } else {
        return `
             background-image: url("/images/downsell-ribbon.svg");
              `;
      }
    }}

    ${({ hideRibbon }) => {
      if (hideRibbon) {
        return `
                    display: none;
                `;
      }
    }}
  }

  &.active:after {
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  ${({ hasChildren }) => {
    if (hasChildren) {
      return `
                padding: 0;
                & .content {
                    padding: 0;

                    &:before {
                        left: 20px;
                    }
                    &:after {
                        left: 21px;
                    }
                    & > div {
                        width: 100%;
                    }
                }
                & label > input:checked+div:before {
                    left: 20px;
                }
                & label > input:checked+div:after {
                    left: 21px;
                }
            `;
    } else {
      return ``;
    }
  }}
`;

export const TubIndexLabel = styled.label`
  position: relative;

  & input:checked + div:before {
    position: absolute;
    content: '';
    top: calc(50% - 10px);
    left: -1px;
    width: 8px;
    height: 18px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index: 1;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  & input:checked + div:after {
    position: absolute;
    content: '';
    top: calc(50% - 9px);
    left: 0;
    border: 2px solid #d26546;
    background-color: #d26546;
    z-index: 0;
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
      height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `

             border: 2px solid #BC2E3E;
            background-color: #BC2E3E;

             

              `;
      }
    }}
  }
`;

export const TubIndexImage = styled.div`
  & > picture > img {
    max-width: 56px !important;

    @media (max-width: 375px) {
      max-width: 60px !important;
      margin-top: 20px;
    }
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                & > picture > img {
                    @media (max-width: 375px) {
                        margin-top: 0px;
                    }
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const TubIndexCheckbox = styled.input`
  display: none;
`;

export const TubIndexContent = styled.div`
  display: flex;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                padding-left: 25px;
                `;
      default:
        return ``;
    }
  }}

  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: calc(50% - 9px);
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  &:after {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1px solid #5a5a5a;
    top: calc(50% - 9px);
    left: 0;
    z-index: 0;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                    border: 1px solid #CCD2E3;
                    border-radius: 50%;
                    
                    `;
        default:
          return ``;
      }
    }}
  }
`;

export const TubIndexContentText = styled.div`
  font-size: 14px;
  margin: 5px 0 0 0;
  line-height: 1.6;
  padding-right: 30px;
  text-align: center;
  padding-left: 15px;
  & > span {
    color: #e26447;
  }

  @media (max-width: 425px) {
    padding-right: 25px;
    padding-left: 15px;
    font-size: 14px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                text-align: left;
                font-size: 16px;
                & > span {
                    color: #BC2E3E;
                }

                `;
      default:
        return;
    }
  }}

  @media (max-width: 500px) {
    & > span {
      display: inline;
    }
  }
`;

export const NoChildren = styled.div`
  padding: 10px;
`;
