import styled from 'styled-components';

export const Trigger = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  text-align: center;
  ${({ modal }) => {
    if (modal) {
      return `
                z-index: 9991;
                position: relative;
            `;
    }
    return ``;
  }}
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;
  background: #ccc;
  opacity: 0.5;
`;

export const Close = styled.div`
  z-index: 9992;
  position: relative;
  cursor: pointer;
`;
