import styled from 'styled-components';

export const Frequency = styled.div`
  & select {
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
  }

  .frequency-select__control,
  .frequency-select__option {
    cursor: pointer;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                & select {
                    background-color: #EEF0F7;
                    border: 1px solid #E5E8F1;
                    color: #001C72;
                }
                & .frequency-select__control {
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                & .frequency-select__menu {
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                `;
      default:
        return ``;
    }
  }}
`;
