import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const NextLayerTimer = loadable(() => import('./NextLayerTimer'));

Builder.registerComponent(NextLayerTimer, {
  name: 'Slides:NextLayerTimer',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'nextLayerId',
      type: 'string',
      defaultValue: 'layer-1',
      required: true
    },
    {
      name: 'timeDelay',
      type: 'number',
      defaultValue: 5000,
      required: true,
      helperText: '1000 for 1 second'
    },
    {
      name: 'insertQueryParam',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'queryParamString',
      type: 'string',
      defaultValue: 'layer',
      showIf: o => o.get('insertQueryParam')
    }
  ]
});
