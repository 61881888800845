import React, { useContext } from 'react';
import * as S from './TubIndexSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';

const TubIndexSelector = props => {
  const { children, tubIndex, hideRibbon } = props;

  const {
    pageDesign,
    setCurrentTubIndex,
    setTubIndex,
    setCurrentProduct,
    currentCategory,
    tubIndexChecked,
    setTubIndexChecked,
    extraObjects,
    setExtraObjects
  } = useContext(FunnelContext);

  const handleTubIndex = () => {
    const productPrefix =
      currentCategory === `onetime` ? `onetime` : `subscription`;

    if (tubIndexChecked) {
      setTubIndex(0);
      setCurrentTubIndex(0);
      setCurrentProduct(`${productPrefix}_0`);
      setTubIndexChecked(false);

      setExtraObjects(
        Object.assign(extraObjects, {
          is_tub_index_checked: 'no'
        })
      );
    } else {
      setTubIndex(tubIndex);
      setCurrentTubIndex(tubIndex);
      setCurrentProduct(`${productPrefix}_${tubIndex}`);
      setTubIndexChecked(true);

      setExtraObjects(
        Object.assign(extraObjects, {
          is_tub_index_checked: 'yes'
        })
      );
    }
  };

  return (
    <S.TubIndexWrapper pageDesign={pageDesign}>
      <S.TubIndex
        className={tubIndexChecked ? `active` : ``}
        hideRibbon={hideRibbon ?? false}
        hasChildren={children}
        pageDesign={pageDesign}
      >
        <S.TubIndexLabel pageDesign={pageDesign} className="label">
          <S.TubIndexCheckbox
            type="checkbox"
            value={tubIndex}
            className="checkbox"
            checked={tubIndexChecked}
            onChange={handleTubIndex}
          />
          <S.TubIndexContent pageDesign={pageDesign} className="content">
            {children && children}
            {!children && (
              <S.NoChildren>- - insert components here - -</S.NoChildren>
            )}
          </S.TubIndexContent>
        </S.TubIndexLabel>
      </S.TubIndex>
    </S.TubIndexWrapper>
  );
};

export default TubIndexSelector;
