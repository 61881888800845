import React, { useContext, useState } from 'react';
import * as S from './PackageSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import loadable from '@loadable/component';

const SubPopup = loadable(() => import('../ProductSelector/SubPopup'));

const PackageSelector = props => {
  const { faqURL, children } = props;

  const { pageDesign } = useContext(FunnelContext);

  const [showSubPopup, setShowSubPopup] = useState(false);

  return (
    <S.Container pageDesign={pageDesign} className="package-selector">
      {children}
      <S.TabContent className="tab-content free-trial">
        <S.SubsPlan pageDesign={pageDesign} className="sub-plan">
          <S.SubsPlanItem pageDesign={pageDesign}>
            <h3>
              <i className="icon icon-pencil"></i>
              Flexible subscription plan
            </h3>
            <p>Change, pause or skip your delivery</p>
          </S.SubsPlanItem>
          <S.SubsPlanItem pageDesign={pageDesign}>
            <h3>
              <i className="icon icon-close"></i>
              Easy Cancel
            </h3>
            <p>
              You can{' '}
              <S.SubPopup
                pageDesign={pageDesign}
                onClick={() => setShowSubPopup(true)}
              >
                manage or cancel your subscription online
              </S.SubPopup>
            </p>
          </S.SubsPlanItem>
        </S.SubsPlan>
      </S.TabContent>
      <SubPopup
        show={showSubPopup}
        setShowSubPopup={setShowSubPopup}
        faqURL={faqURL}
      />
    </S.Container>
  );
};

export default PackageSelector;
