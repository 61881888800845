import styled from 'styled-components';

export const Container = styled.div`
  color: #001c72;
  background: #ffffff;
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  min-height: 60px;
`;

export const Icon = styled.img`
  width: 20px;
  position: absolute;
`;

export const ItemContainer = styled.div`
  display: block;
  position: relative;
  ${({ hasIcon }) =>
    hasIcon
      ? `
    margin-left: 30px;
    `
      : `margin-left: 5px`}
`;

export const Label = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  color: #001c72;
  letter-spacing: 2px;
  margin-right: 10px;
`;

export const Title = styled.span`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #001c72;
  display: block;
`;

export const SubTitle = styled.span`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #001c72;
  display: block;
`;

export const Tag = styled.span`
  color: #fff;
  background-color: #d7828b;
  border-radius: 4px;
  padding: 3px 5px;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: calc(50% - 10px);
`;

export const Button = styled.div`
  width: 40px;
  display: flex;
  cursor: pointer;
  border-right: 1px solid #ccd2e3;
  & > span {
    display: block;
    margin: auto;
    width: 6px;
    height: 6px;
    font-size: 30px;
    line-height: 0;
    font-weight: bold;
  }

  &.plus {
    margin-left: auto;
    border-left: 1px solid #ccd2e3;
    border-right: none;
    & > span {
      font-size: 25px;
      width: 10px;
      height: 10px;
      line-height: 11px;
      align-self: end;
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
        opacity: 0.5;
        border-color: #E6E8F1;
        &.plus {
            border-color:#E6E8F1;
        }
    `}
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const Body1 = styled.div`
  margin-left: 15px;
  display: block;
  width: 100%;
  padding: 10px;
`;
export const Body2 = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-right: 5px;
`;
