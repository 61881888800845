import { Builder, withChildren } from '@builder.io/react';
import { timezones } from '../../utils/datetime';
import loadable from '@loadable/component';

const TimeLimitContainer = loadable(() => import('./TimeLimitContainer'));

Builder.registerComponent(withChildren(TimeLimitContainer), {
  name: 'Time Limit Container',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  canHaveChildren: true,
  defaultStyles: {
    height: '100px'
  },
  inputs: [
    {
      name: 'design',
      type: 'string',
      enum: ['design1'],
      defaultValue: 'design1'
    },
    {
      name: 'startMonth',
      type: 'string',
      enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      defaultValue: '1'
    },
    {
      name: 'startDay',
      type: 'string',
      enum: Array.from(Array(32).keys()).filter(n => n !== 0),
      defaultValue: '1'
    },
    {
      name: 'startYear',
      type: 'string',
      defaultValue: '2021'
    },
    {
      name: 'startHour',
      type: 'string',
      enum: Array.from(Array(24).keys()),
      defaultValue: '0'
    },
    {
      name: 'startMinutes',
      type: 'string',
      enum: Array.from(Array(60).keys()),
      defaultValue: '0'
    },
    {
      name: 'endMonth',
      type: 'string',
      enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      defaultValue: '1'
    },
    {
      name: 'endDay',
      type: 'string',
      enum: Array.from(Array(32).keys()).filter(n => n !== 0),
      defaultValue: '1'
    },
    {
      name: 'endYear',
      type: 'string',
      defaultValue: '2021'
    },
    {
      name: 'endHour',
      type: 'string',
      enum: Array.from(Array(24).keys()),
      defaultValue: '0'
    },
    {
      name: 'endMinutes',
      type: 'string',
      enum: Array.from(Array(60).keys()),
      defaultValue: '0'
    },
    {
      name: 'timezone',
      type: 'string',
      enum: timezones,
      defaultValue: 'Europe/London'
    },
    {
      name: 'hideOnExpiry',
      type: 'boolean',
      defaultValue: true
    }
  ]
});
