import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const FBCommentPlugin = loadable(() => import('./FBCommentPlugin'));

Builder.registerComponent(FBCommentPlugin, {
  name: 'Facebook Comment Plugin',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'url',
      type: 'string',
      defaultValue:
        'https://www.facebook.com/thepetlabco/posts/718096255784526?comment_id=726429981617820'
    },
    {
      name: 'width',
      type: 'number',
      defaultValue: 750
    }
  ]
});
