import { Builder } from '@builder.io/react';
import PricePerChew from './PricePerChew';

Builder.registerComponent(PricePerChew, {
  name: 'Price Per Chew',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'showPricePer',
      type: 'string',
      enum: ['chew', 'tub'],
      defaultValue: 'chew'
    },
    {
      name: 'pricePerText',
      type: 'string',
      defaultValue: 'chew'
    },
    {
      name: 'productIndex',
      type: 'number',
      defaultValue: 1,
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'productQuantity',
      type: 'number',
      defaultValue: 1,
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'showIndex0',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    }
  ]
});
