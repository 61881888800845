import styled from 'styled-components';

export const Container = styled.div``;

export const OneTimeContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  ${({ active }) =>
    active &&
    `
        opacity: 1;
        background: #FCF1E0;
        border: 1px solid #6677AA;
    `}
`;

export const SubscriptionContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  ${({ active }) =>
    active &&
    `
        opacity: 1;
        background: #FCF1E0;
        border: 1px solid #6677AA;
    `}
`;

export const WhySubscribeContainer = styled.div`
  background: #e6e8f1;
  border-radius: 4px;
  padding: 12px;
  margin-top: 28px;
  position: relative;
  &::before {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #e6e8f1;
    display: block;
    content: ' ';
    position: absolute;
    top: -15px;
    left: 80px;
  }
`;

export const RadioInput = styled.div`
  border: 1px solid #001c72;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 100%;

  ${({ active }) =>
    active &&
    `
        &::before {
            background-color:#001C72;
            display: block;
            content: " ";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            margin: 3px;
        }
    `}
`;

export const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #001c72;
  margin-left: 10px;
  &.title2 {
    text-transform: uppercase;
  }
`;

export const SubIcon = styled.img`
  margin-left: 8px;
`;
export const SubIcon2 = styled.img``;

export const ListContainer = styled.ul`
  margin-bottom: 0;
`;

export const ListItem = styled.li``;

export const SecondLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const DiscountedPrice = styled.div`
  font-family: Utopia Std;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 0;
  display: flex;
  align-items: center;
  color: #001c72;
  ${({ sub }) =>
    sub &&
    `
        color: #BC2E3E;
    `}
`;

export const Price = styled.div`
  font-family: Utopia Std;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 0;
  display: flex;
  align-items: center;
  text-decoration-line: line-through;
  margin-left: 8px;
  margin-right: 8px;
  color: #33498e;
  ${({ sub }) =>
    sub &&
    `
        color: #33498E;
    `}
`;

export const Savings = styled.div`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #6677aa;
  display: flex;
  align-items: center;
  ${({ sub }) =>
    sub &&
    `
        color: #BC2E3E;
    `}
`;

export const FrequencyLabel = styled.div`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #001c72;
  margin: 19px 0 12px;
`;

export const SubPopup = styled.span`
  color: #bc2e3e;
  text-decoration: underline;
  cursor: pointer;
`;
