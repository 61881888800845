import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const DelayCalendar = loadable(() => import('./DelayCalendar'));

Builder.registerComponent(DelayCalendar, {
  name: 'Calendar',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'locale',
      type: 'string',
      enum: ['en-us', 'en-uk', 'en-ca', 'fr-ca', 'de-de'],
      defaultValue: 'en-us'
    },
    {
      name: 'calendarType',
      type: 'string',
      enum: ['ISO 8601', 'US', 'Arabic', 'Hebrew'],
      defaultValue: 'ISO 8601',
      helperText:
        'Setting to "US" will change the first day of the week to Sunday.'
    },
    {
      name: 'showNeighboringMonth',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'prev2Label',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'next2Label',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'maxDetail',
      type: 'string',
      enum: ['month', 'year', 'decade', 'century'],
      defaultValue: 'month',
      helperText:
        'The most detailed view that the user shall see. View defined here also becomes the one on which clicking an item will select a date and pass it to onChange.'
    },
    {
      name: 'minDetail',
      type: 'string',
      enum: ['month', 'year', 'decade', 'century'],
      defaultValue: 'century',
      helperText: 'The least detailed view that the user shall see.'
    },
    {
      name: 'minDate',
      type: 'date'
    },
    {
      name: 'maxDate',
      type: 'date'
    }
  ]
});
