import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const FloatingCountdown = loadable(() => import('./FloatingCountdown'));

Builder.registerComponent(withChildren(FloatingCountdown), {
  name: 'Floating Countdown',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'discount',
      type: 'number',
      defaultValue: 25
    },
    {
      name: 'durationSeconds',
      type: 'number',
      defaultValue: 1200,
      showIf: options => options.get('showTimer')
    },
    {
      name: 'position',
      type: 'string',
      defaultValue: 'right',
      enum: ['right', 'left']
    },
    {
      name: 'showTimer',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'showCustomText',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'customText',
      type: 'richText',
      defaultValue: '25% discount applied and reserved for you! Special Offer.',
      showIf: o => o.get('showCustomText')
    },
    {
      name: 'addDelayTime',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'delayTime',
      type: 'number',
      defaultValue: 0,
      helperText: 'in seconds',
      showIf: o => o.get('addDelayTime')
    },
    {
      name: 'addClosingTime',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'closingTime',
      type: 'number',
      defaultValue: 10,
      helperText: 'in seconds',
      showIf: o => o.get('addClosingTime')
    },
    {
      name: 'showTransition',
      type: 'string',
      defaultValue: 'none',
      enum: ['none', 'slide-up']
    },
    {
      name: 'closeTransition',
      type: 'string',
      defaultValue: 'none',
      enum: ['none', 'slide-down']
    },
    {
      name: 'transitionSpeed',
      type: 'string',
      defaultValue: 1,
      enum: [1, 2, 3, 4, 5]
    },
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#FFFFFF'
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#000000'
    },
    {
      name: 'hideCloseButton',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
