import React from 'react';
import TubIndexSelectorV1 from './TubIndexSelectorV1';

const TubIndexSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case 'version1':
      return <TubIndexSelectorV1 {...props} />;
  }
};

export default TubIndexSelector;
