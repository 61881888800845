import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const LocalCartText = loadable(() => import('./LocalCartText'));

Builder.registerComponent(LocalCartText, {
  name: 'Local Cart Text',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'displayValue',
      type: 'string',
      enum: [
        'main-product-name',
        'main-product-price',
        'main-product-discounted-price',
        'main-product-display-title',
        'main-product-category',
        'main-product-frequency',
        'free-gift-name',
        'free-gift-value',
        'total-amount',
        'total-savings'
      ],
      defaultValue: `main-product-name`
    }
  ]
});
