import { Builder, withChildren } from '@builder.io/react';
import ExtraObjectsContainer from './ExtraObjectsContainer';

Builder.registerComponent(withChildren(ExtraObjectsContainer), {
  name: 'Extra Objects Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'type',
      type: 'string',
      enum: ['single', 'multiple', 'list-single', 'list-multiple'],
      defaultValue: 'single',
      required: true
    },
    {
      name: 'propertyKey',
      type: 'string',
      defaultValue: '',
      required: true,
      showIf: o => ['single', 'list-single'].includes(o.get('type'))
    },
    {
      name: 'propertyValue',
      type: 'string',
      defaultValue: '',
      required: false,
      showIf: o => ['single'].includes(o.get('type'))
    },
    {
      name: 'propertys',
      type: 'list',
      required: false,
      showIf: o => ['multiple'].includes(o.get('type')),
      subFields: [
        {
          name: 'propertyKey',
          type: 'string',
          defaultValue: '',
          required: true
        },
        {
          name: 'propertyValue',
          type: 'string',
          defaultValue: '',
          required: false
        }
      ]
    },
    {
      name: 'containers',
      type: 'list',
      defaultValue: '',
      required: false,
      showIf: o => ['list-single', 'list-multiple'].includes(o.get('type')),
      subFields: [
        {
          name: 'propertyList',
          type: 'list',
          required: false,
          subFields: [
            {
              name: 'value',
              type: 'string',
              defaultValue: '',
              required: false
            },
            {
              name: 'keyIfListMultipleType',
              type: 'string',
              defaultValue: '',
              required: false
            }
          ]
        }
      ]
    },
    {
      name: 'defaultContainer',
      type: 'number',
      defaultValue: 0,
      required: true,
      showIf: o => ['list-single', 'list-multiple'].includes(o.get('type'))
    },
    {
      name: 'showDefaultOnMissingproperty',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['list-single', 'list-multiple'].includes(o.get('type'))
    }
  ],
  noWrap: true
});
