import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const SlickSlider = loadable(() => import('./SlickSlider'));

Builder.registerComponent(withChildren(SlickSlider), {
  name: 'Slick Slider',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'items',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'showIn',
          type: 'list',
          defaultValue: [],
          subFields: [
            {
              name: 'currentCategory',
              type: 'string',
              enum: ['subscriptions', 'onetime'],
              defaultValue: 'subscriptions'
            },
            {
              name: 'currentTubIndex',
              type: 'number',
              defaultValue: 0
            }
          ]
        }
      ]
    },
    {
      name: 'accessibility',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'adaptiveHeight',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'arrows',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'arrowsColor',
      type: 'color',
      defaultValue: '',
      showIf: o => o.get('arrows')
    },
    {
      name: 'arrowsBgColor',
      type: 'color',
      defaultValue: '',
      showIf: o => o.get('arrows')
    },
    {
      name: 'autoplaySpeed',
      type: 'number',
      defaultValue: 3000
    },
    {
      name: 'autoplay',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'centerMode',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'centerPadding',
      type: 'number',
      defaultValue: 50
    },
    {
      name: 'className',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'dots',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'draggable',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'easing',
      type: 'string',
      defaultValue: 'linear'
    },
    {
      name: 'fade',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'focusOnSelect',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'infinite',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'initialSlide',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'lazyLoad',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'pauseOnDotsHover',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'pauseOnFocus',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'pauseOnHover',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'rows',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'rtl',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'slidesPerRow',
      type: 'number',
      defaultValue: 3
    },
    {
      name: 'slidesToScroll',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'slidesToShow',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'speed',
      type: 'number',
      defaultValue: 500
    },
    {
      name: 'swipeToSlide',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'swipe',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'touchMove',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'touchThreshold',
      type: 'number',
      defaultValue: 5
    },

    {
      name: 'useTransform',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'variableWidth',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'vertical',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
