import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #0000001a;
`;

export const CATContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #f0f0f08c;
    border-radius: 4px;
  }
  ${({ active }) => {
    if (active) {
      return `
            background-color: #E6E8F1;
            &:hover {
                background-color: #E6E8F1;
                border-radius: 4px;
            }
            `;
    }
  }}

  & .fade-enter {
    opacity: 0;
  }
  & .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in, margin 300ms ease-out;
  }
  & .fade-exit {
    opacity: 1;
  }
  & .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out, margin 300ms ease-in;
  }
`;

export const Dot = styled.div`
  border: 1px solid #ccd2e3;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  background-color: #fff;
  position: absolute;
  left: 15px;

  ${({ active }) => {
    if (active) {
      return `
        border: 1px solid #001C72;
        
        &:after {
            width: 9px;
            height: 9px;
            display:block;
            background-color:#001C72;
            content: " ";
            border-radius: 50%;
            top: 3px;
            left: 3px;
            position:absolute;
        }
        `;
    }
  }}
`;

export const Label = styled.span`
  ${({ active }) => {
    if (active) {
      return `
        font-weight: 700;
        width: 90%;
        `;
    }
  }}
`;

export const RedText = styled.span`
  font-weight: 700;
  color: #bc2e3e;
`;

export const Selector = styled.div`
  font-family: 'Neuzeit Grotesk';
  font-style: normal;
  font-size: 20px;
  line-height: 21px;
  padding: 16px;
  padding-left: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  ${({ active }) => {
    if (active) {
      return `
            background-color: #E6E8F1;
            `;
    }
  }}
  ${({ isSub }) => {
    if (isSub) {
      return `border-radius: 0 0 4px 4px;`;
    }
    return `border-radius: 4px 4px 0 0;`;
  }}
`;
export const ChildContainer = styled.div``;
