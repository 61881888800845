import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import { ProductData } from '../../utils/productPrice';
import * as S from './ComparePlansTable.styles';

const ComparePlansTable = ({ columns }) => {
  const context = useContext(FunnelContext);

  const { currency } = context;

  const currencySymbol = currency?.symbol || '$';

  const newColumns = columns.map(column => {
    const { category, tubIndex } = column;

    const currentProduct =
      category === `onetime`
        ? `onetime_${tubIndex}`
        : `subscription_${tubIndex}`;

    const [productData] = ProductData({
      currentCategory: category,
      currentProduct: currentProduct
    });

    const discountedPrice = productData?.checkoutData.discounted_price.toFixed(
      2
    );
    const discountedPricePerChew = (
      productData?.checkoutData.discounted_price /
      (30 * productData?.checkoutData.quantity)
    ).toFixed(2);
    const discountedPricePerTub = (
      productData?.checkoutData.discounted_price /
      productData?.checkoutData.quantity
    ).toFixed(2);
    const savings =
      productData?.checkoutData.perceived_rrp -
      productData?.checkoutData.discounted_price;
    const savingsText = savings
      ? `${currencySymbol}${savings.toFixed(2)}`
      : `-`;

    return {
      ...column,
      discountedPrice,
      discountedPricePerChew,
      discountedPricePerTub,
      savingsText
    };
  });
  return (
    <S.Container>
      <S.Table>
        <S.Thead>
          <tr>
            <th> </th>
            {newColumns.map(({ label }, index) => (
              <th className="min-width-190" key={index.toString()}>
                {label}
              </th>
            ))}
          </tr>
        </S.Thead>
      </S.Table>
      <S.Table className="body" cellSpacing="0" cellPadding="0">
        <S.Tbody>
          <tr className="top">
            <td className="label radius-top-left">Free Gift</td>
            {newColumns.map(({ freeGift }, index) => (
              <td className="min-width-190" key={index.toString()}>
                {freeGift ? (
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F53d2798df5dc4128accb0f329bd82a1e"
                    alt={`free-gift-check-${index}`}
                  />
                ) : (
                  `-`
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td className="label">Free Shipping</td>
            {newColumns.map(({ freeShipping }, index) => (
              <td key={index.toString()}>
                {freeShipping ? (
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F53d2798df5dc4128accb0f329bd82a1e"
                    alt={`free-shipping-check-${index}`}
                  />
                ) : (
                  `-`
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td className="label">Chews / Tub(s)</td>
            {newColumns.map(({ chewsTubs }, index) => (
              <td key={index.toString()}>{chewsTubs}</td>
            ))}
          </tr>
          <tr>
            <td className="label">Cost Per Chew</td>
            {newColumns.map(({ discountedPricePerChew }, index) => {
              return (
                <td key={index.toString()} className="text-bold">
                  {currencySymbol}
                  {discountedPricePerChew}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className="label">Cost Per Tub</td>
            {newColumns.map(({ discountedPricePerTub }, index) => {
              return (
                <td key={index.toString()}>
                  {currencySymbol}
                  {discountedPricePerTub}
                </td>
              );
            })}
          </tr>
          <tr>
            <td className="label">Savings</td>
            {newColumns.map(({ savingsText }, index) => {
              return <td key={index.toString()}>{savingsText}</td>;
            })}
          </tr>
          <tr>
            <td className="label">Frequency</td>
            {newColumns.map(({ frequency }, index) => (
              <td key={index.toString()}>{frequency}</td>
            ))}
          </tr>
          <tr>
            <td className="label radius-bottom-left">Total Pricing</td>
            {newColumns.map(({ discountedPrice }, index) => {
              return (
                <td key={index.toString()}>
                  {currencySymbol}
                  {discountedPrice}
                </td>
              );
            })}
          </tr>
        </S.Tbody>
      </S.Table>
    </S.Container>
  );
};

export default ComparePlansTable;
