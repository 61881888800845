import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 500px) {
    &.wide {
      & > ul {
        max-width: 100% !important;
        & li {
          width: 100%;
          & .box {
            width: 100% !important;
            flex-direction: row;
            padding-left: 10px;
            padding-right: 10px;

            & > picture {
              width: 150px;
            }
          }
        }
      }
    }
  }
`;

export const TubList = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (max-width: 500px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const TubListItem = styled.div`
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  padding: 0;
  cursor: pointer;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.active > div {
    opacity: 1;
  }

  &.current > div {
    border: 1px solid
      ${({ pageDesign }) => (pageDesign === `design2` ? '#AD3B42' : '#e26447')};
    -webkit-box-shadow: 0 0 5px 0
      ${({ pageDesign }) => (pageDesign === `design2` ? '#AD3B42' : '#e26447')};
    box-shadow: 0 0 5px 0
      ${({ pageDesign }) => (pageDesign === `design2` ? '#AD3B42' : '#e26447')};
    opacity: 1;
  }

  @media (max-width: 500px) {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 5px;
  }
`;

export const TubListItemContainer = styled.div`
  border: 1px solid
    ${({ pageDesign }) => (pageDesign === `design2` ? '#E6E8F0' : '#dbdbdb')};
  border-radius: 2px;
  text-align: center;
  padding: 12px 0;
  width: 124px;
  color: ${({ pageDesign }) =>
    pageDesign === `design2` ? '#001C72' : '#213f4e'};
  font-size: 16px;
  line-height: 19px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  ${({ backgroundColor }) => {
    if (backgroundColor && backgroundColor.trim().length > 0) {
      return `background-color: ${backgroundColor};`;
    }
  }}

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `box-shadow: 0px 0px 6px #0000001A`;
    }
  }}

    @media (max-width: 1071px) {
    width: 110px;
  }

  @media (max-width: 1000px) {
    width: 150px;
  }

  @media (max-width: 1000px) {
    width: 150px;
  }

  @media (max-width: 510px) {
    width: 150px;
  }

  @media (max-width: 320px) {
    width: 130px;
  }

  & img {
    height: 38px;
    display: block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin: 0 auto;
  }
  & > span {
    font-weight: ${({ pageDesign }) =>
      pageDesign === `design2` ? 'normal' : '600'};
    margin-top: 7px;
    display: block;
    white-space: nowrap;
  }
`;

export const Price = styled.div``;

export const NoChildren = styled.div`
  font-size: 10px;
  color: red;
  padding: 10px;
  border: 1px solid red;
  line-height: 1rem;
`;
