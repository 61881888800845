import { Builder } from '@builder.io/react';
import FreeGiftSelector from './FreeGiftSelector';

Builder.registerComponent(FreeGiftSelector, {
  name: 'Free Gift Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'version',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'property_name',
      type: 'string',
      defaultValue: 'free_gift_variant_id'
    },
    {
      name: 'items',
      type: 'list',
      defaultValue: '',
      subFields: [
        {
          name: 'value',
          type: 'string',
          defaultValue: 'value'
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Label'
        },
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'default',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'extraObjects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              required: true
            },
            {
              name: 'value',
              type: 'string'
            }
          ]
        }
      ]
    }
  ]
});
