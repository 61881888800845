import React from 'react';
import loadable from '@loadable/component';

const CookieContainerSingle = loadable(() => import('./CookieContainerSingle'));
const CookieContainerMultiple = loadable(() =>
  import('./CookieContainerMultiple')
);
const CookieContainerList = loadable(() => import('./CookieContainerList'));
const CookieContainerListMultiple = loadable(() =>
  import('./CookieContainerListMultiple')
);

const CookieContainer = props => {
  const { type } = props;
  switch (type) {
    case 'list-multiple':
      return <CookieContainerListMultiple {...props} />;
    case 'list-single':
      return <CookieContainerList {...props} />;
    case 'multiple':
      return <CookieContainerMultiple {...props} />;
    default:
    case 'single':
      return <CookieContainerSingle {...props} />;
  }
};

export default CookieContainer;
