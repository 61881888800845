import React, { useContext } from 'react';
import * as S from './ProductTitleV1.styles';
import { ProductPrice } from '../../utils/productPrice';
import FunnelContext from '../../context/FunnelContext';

const Star = () => {
  return <S.Star />;
};

const ProductTitle = props => {
  const context = useContext(FunnelContext);
  const { currentCategory, currentProduct } = context;

  const { name, tubs, reviews } = props;

  const currentCategoryDefault = currentCategory ?? 'subscriptions';
  const currentProductDefault = currentProduct ?? 'subscription_0';

  return (
    <S.Container>
      <S.Name>{name}</S.Name>
      <S.Tubs>
        <span>IN STOCK</span> - {tubs} CHEWS PER TUB
      </S.Tubs>
      <S.Reviews>
        <S.Stars>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </S.Stars>
        <S.ReviewText>{reviews} FIVE STAR REVIEWS</S.ReviewText>
      </S.Reviews>
      <S.ProductPrices>
        <div>
          <span>
            WAS $
            <ProductPrice
              type="price"
              currentCategory={currentCategoryDefault}
              currentProduct={currentProductDefault}
            />
          </span>
          <h2>
            $
            <ProductPrice
              type="discounted_price"
              currentCategory={currentCategoryDefault}
              currentProduct={currentProductDefault}
            />
          </h2>
        </div>
        <p>
          {' '}
          Sign up to subscription to get an additional <span>10</span>% off of
          your order!{' '}
        </p>
      </S.ProductPrices>
    </S.Container>
  );
};

export default ProductTitle;
