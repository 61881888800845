import { Builder, withChildren } from '@builder.io/react';
import FatherLayerContainer from './FatherLayerContainer';

Builder.registerComponent(withChildren(FatherLayerContainer), {
  name: 'Father Layer Container',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: []
});
